import { createSlice } from '@reduxjs/toolkit'
import CookiesHelper from '../../helpers/CookiesHelper';


export const wordsPerPageSlice = createSlice({
  name: 'wordsPerPage',
  initialState: {
    value: CookiesHelper.getWordsPerPage()
  },
  reducers: {
    set: (state, action) => {
      state.value = Math.min(Math.max(action.payload,1),15);
    }
  },
})

export const { set } = wordsPerPageSlice.actions

export default wordsPerPageSlice.reducer