import {useEffect, useState} from "react";
import {Dialog, Button} from "@mui/material";
import { useDispatch } from 'react-redux'
import { remove as removeWord } from '../slice/words/wordsListSlice'
import { update as updateWord } from '../slice/words/wordsListSlice'
import {Box, Paper, InputLabel} from '@mui/material';
import Api from "../api/wordsApi";
import Grid from '@mui/material/Grid';


import {WirdEditAlertBar, WordEditStatus} from './WordEditAlertBar';
import WordEditBox from './WordEditBox';

//TODO: whole component to review (135lines of code...)
function EditWordDialog(props){
    return(<Dialog
        open={props.open}
        onClose={props.handleClose}
    >   
    <Paper className='standard-margin' elevation={3}>
        <Box width="100%" height="100%">
            <EditExistingWord baseWord={props.word} handleClose={props.handleClose} />
        </Box>
    </Paper>
    </Dialog >);
}


// move to different component
function EditExistingWord(props){
    const [wordFormInput, setWordFormInput] = useState({});
    const [wordTextError, setWordTextError] = useState(false);
    const [translationTextError, setTranslationTextError] = useState(false);
    const [wordAddStatus, setWordAddStatus] = useState(WordEditStatus.None);

    const dispatch = useDispatch();
    
    const handleWordInputChange = (event, value) => {
        setWordFormInput(value);
    }

    useEffect(() =>
        {setWordFormInput(props.baseWord)},
    [props.baseWord]);


    async function editWord(wordToSave) {
        const response = await Api.editWord(props.baseWord.word,props.baseWord.translation, wordToSave); 
        return await response;
    }

    
    async function deleteWord() {
        const response = await Api.deleteWord(props.baseWord.word,props.baseWord.translation);
        return await response;
    }

    const sleep = ms => new Promise(r => setTimeout(r, ms));
    
    async function EditWordClick(){
        setWordAddStatus(WordEditStatus.None);
        if(_isWordFieldValid() && _isTranslationieldValid() && _isBaseWordValid()){
            let result = await editWord(wordFormInput);
            if(result && result.words.length!==0) {
                dispatch(updateWord({oldId:props.baseWord.id, newWord:result.words[0]}));
                setWordAddStatus(WordEditStatus.EditSuccess);
                _markRequiredFields();
                await sleep(500);
                props.handleClose();
            } else {
                setWordAddStatus(WordEditStatus.AlreadyExist);
                _wordAlreadyExists()
            }
        } else {
            setWordAddStatus(WordEditStatus.BadInput);
            _markRequiredFields()
        }
    }

    async function DeleteWordClick(){
        setWordAddStatus(WordEditStatus.None); //TODO change name 'WordAddStatus' to WordsActionStatus

        if(_isBaseWordValid()){
            await deleteWord();
            dispatch(removeWord(props.baseWord.id));
            setWordAddStatus(WordEditStatus.DeleteSuccess);//TODO: some result validation
        } else {
            setWordAddStatus(WordEditStatus.BadInput);
        }
        await sleep(500);
        props.handleClose();
    }

    function _markRequiredFields() { 
        setWordTextError(!_isWordFieldValid());
        setTranslationTextError(!_isTranslationieldValid());
    }

    function _wordAlreadyExists() { 
        setWordTextError(true);
        setTranslationTextError(true);
    }
    
    function _isWordFieldValid(){
        return (wordFormInput.word && wordFormInput.word.trim() !== '')
    }

    function _isTranslationieldValid(){
        return (wordFormInput.translation && wordFormInput.translation.trim() !== "")
    }

    function _isBaseWordValid(){
        return (props.baseWord &&
            (props.baseWord.word && props.baseWord.word.trim() !== "") && 
            (props.baseWord.translation && props.baseWord.translation.trim() !== ""));
    }

    return(<div>
        <Grid container spacing={1.5}>
            <Grid item xs={8}>
                <InputLabel size="large">Edit word:</InputLabel>
            </Grid>
            <Grid item xs={4}>
                <Button fullWidth={true} color="error" variant="contained" size="large" onClick={DeleteWordClick}>Delete</Button>
            </Grid>
            <Grid item xs={12}>
                <WordEditBox 
                    handleInputChange = {handleWordInputChange}
                    wordTextError = {wordTextError}
                    translationTextError = {translationTextError}
                    inputs={props.baseWord} />
            </Grid>
            <Grid item xs={4}>
                <Button fullWidth={true} color="error" variant="outlined" size="large" onClick={props.handleClose}>Cancel</Button>
            </Grid>
            <Grid item xs={8}>
                <Button fullWidth={true} color="success" variant="contained" size="large" onClick={EditWordClick}>Save</Button>
            </Grid>
            <Grid item xs={12}>
                <WirdEditAlertBar wordAddStatus={wordAddStatus} />
            </Grid>
        </Grid>
        </div>)
}

export default EditWordDialog;