import Alert from '@mui/material/Alert';
import {useEffect, useState} from "react";

function WirdEditAlertBar(props){
    const [wordAddStatus, setWordAddStatus] = useState("");

    useEffect(() => {
        setWordAddStatus(props.wordAddStatus);
    },[props.wordAddStatus]);

    function statusSuccess () {
        return wordAddStatus === WordEditStatus.Success;
    } 

    function statusAlreadyExists () {
        return wordAddStatus === WordEditStatus.AlreadyExist;
    } 

    function statusDeleteSuccess () {
        return wordAddStatus === WordEditStatus.DeleteSuccess;
    } 

    function statusEditSuccess () {
        return wordAddStatus === WordEditStatus.EditSuccess;
    } 

    function statusBadParameters () {
        return wordAddStatus === WordEditStatus.BadInput;
    } 

    return (
        <div>
            {statusSuccess() && <Alert severity="success">Word added successfully.</Alert>}
            {statusEditSuccess() && <Alert severity="success">Word updated successfully.</Alert>}
            {statusDeleteSuccess() && <Alert severity="success">Word deleted successfully.</Alert>}
            {statusBadParameters() && <Alert severity="warning">Word and translation filed needs to be filled.</Alert>}
            {statusAlreadyExists() && <Alert severity="error">Word already exists.</Alert>}
        </div>
    );
  }

  const WordEditStatus = {
    None: 'none',
    Success: 'success',
    EditSuccess: 'edit_success',
    DeleteSuccess: 'delete_success',
    AlreadyExist: 'alread_exists',
    BadInput: 'bad_input'
  };

  export {WirdEditAlertBar, WordEditStatus};