import OptionsMainWindow from "./components/options/OptionsMainWindow";
import {StartPage} from "./StartPage";
import Fab from '@mui/material/Fab';
import SettingsIcon from '@mui/icons-material/Settings';
import Paper from '@mui/material/Paper';
import {useState} from "react";

  function App() {
    const [isOptionMenuOpen, setIsOptionMenuOpen] = useState(false); //TODO: after tests switch to false

    function openOptions(){
      setIsOptionMenuOpen(true);
    }

    function closeOptions(){
      setIsOptionMenuOpen(false);
    }


  return (
      <div className="App">
        <OptionsMainWindow isOptionMenuOpen={isOptionMenuOpen} handleClose={closeOptions}/>
        <div>
          <Paper className='standard-margin' elevation={3}>
          <div className="edit-fab-style"><Fab color="success" size= "small" aria-label="edit" onClick={openOptions}><SettingsIcon/></Fab></div>
          <div><StartPage/></div>
          </Paper>
        </div>
      </div>
  );
}


export default App;
