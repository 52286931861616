let Api = {
    getTags:getTags,
    getWords:getWords,
    addWord:addWord,
    editWord:editWord,
    deleteWord:deleteWord
}

async function getTags() { 
    const response = await fetch('/api/tags', {
        method: "GET", headers: {
            "Content-Type": "application/json",
        }
    });   
    const tagsResponse = await response.json();
    return tagsResponse;
}

async function getWords(fromTags, withTags, withOutTags) {
    const response = await fetch("/api/words?" + new URLSearchParams({
      from:fromTags,
      with:withTags,
      withOut:withOutTags
    }), {
        method: "GET", headers: {
            "Content-Type": "application/json",
        }
    });   
    const wordsResponse = await response.json();
    return wordsResponse.words;
}

async function editWord(word, translation, updatedWord) {
    const response = await fetch('/api/words?'  + new URLSearchParams({
        word:word,
        translation:translation
      }),
      {
        method: "PUT", 
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedWord)
    });   
    return await response.json();
}

async function deleteWord(word, translation) {
    const response = await fetch('/api/words?'  + new URLSearchParams({
        word:word,
        translation:translation
      }),
      {
        method: "DELETE", 
        headers: {
            "Content-Type": "application/json",
        }
    });   
    return await response.json();
}

async function addWord(wordToSave) {
    let requestBody = {
        "words":[wordToSave]
    }
    const response = await fetch('/api/words', {
        method: "POST", 
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody)
    });   
    return await response.json();
}

export default Api;