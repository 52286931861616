import WordsTableRow from "./WordsTableRow";
import DescriptionTooltip from './DescriptionTooltip';
import * as React from 'react';

 function WordsTablePage(props){
    let wordsOnPage = props.wordsOnPage;
    let reverse = (props.reverse && true);

    function _prepareWordCellSet(){ //TODO: maybe this should be done on row lvl not page?
        return wordsOnPage.map(v => {
            let wordFormatted = <div className="word-font">{v.word}</div>;
            //TODO: might move whole translation and word window to two separate components
            let translationFormatted = (<DescriptionTooltip title = { v.translationDescription}> 
                        <font className="article-font">{v.translationDetails}</font>
                        <font className="translation-font">&nbsp;{v.translation}</font>
                </DescriptionTooltip>);
            return (<WordsTableRow fronSide={wordFormatted} backSide={translationFormatted} tags={v.tags} reverse={reverse} word={v} />); 
        })
    }

    return ( //TODO: move to css, better values
        <table>
            <tr>
                <th width="46%">Word</th> 
                <th width="46%">Translation</th>
                <th width="6%"></th>
            </tr>
            {_prepareWordCellSet()}
        </table>)
}

export default WordsTablePage