import { createSlice } from '@reduxjs/toolkit'
import CookiesHelper from '../../helpers/CookiesHelper';

export const reversCardSlice = createSlice({
  name: 'reverseCard',
  initialState: {
    value: CookiesHelper.getReverseCard()
  },
  reducers: {
    enable: (state) => {
      state.value = true;
    },
    disable: (state) => {
      state.value = false;
    }
  },
})

export const { enable, disable } = reversCardSlice.actions

export default reversCardSlice.reducer