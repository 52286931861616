import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

function DescriptionTooltip(props){ //move to different component
function ApplyNewLines(text) {
  if(text && text.trim()!==0){
    let description = text.trim();
    return description.split('\n').map(str => <div style={{ whiteSpace: 'pre'}}>{str}</div>);;
  }
}

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: '20px',
      border: '1px solid #dadde9',
    },
  }));

return (<HtmlTooltip 
    {...props}
    title={ApplyNewLines(props.title)} 
    enterTouchDelay={0} 
    leaveTouchDelay={10000}
    placement="top" 
    />)
}

export default DescriptionTooltip;