import {useEffect, useState, useRef} from "react";
import {WordsTable} from "./components/wordsTable/WordsTable";
import { useDispatch } from 'react-redux'
import { set as setWordsList } from './slice/words/wordsListSlice'
import TagSelectBox from "./components/TagSelectBox";
import Grid from '@mui/material/Grid';
import Api from './api/wordsApi';
import Cookies from 'js-cookie';
import './App.css';


function StartPage(props) {
    const [tagsList, setTagsList] = useState([]);
    const [fromTags, setFromTags] = useState([]);
    const [withTags, setWithTags] = useState([]);
    const [withOutTags, setWithOutTags] = useState([]);
    let firstUpdate = useRef(true); //prevent from multiple reloading with initial values, ask someone smart how to solve this
    
    const dispatch = useDispatch();

    useEffect(() => {
      async function getTags() { 
        let result = await Api.getTags();
        setTagsList(result);
      }  
      async function getTagsFromCookies(){
        let cookiesFromTags = Cookies.get('fromTags')??'[]';
        let cookiesWithTags = Cookies.get('withTags')??'[]';
        let cookiesWithOutTags = Cookies.get('withOutTags')??'[]';
        setFromTags(JSON.parse(cookiesFromTags));
        setWithTags(JSON.parse(cookiesWithTags));
        setWithOutTags(JSON.parse(cookiesWithOutTags));
      }
      getTags();
      getTagsFromCookies();
    },[]);


    useEffect(() => {
      async function getWords() {
        let result = await Api.getWords(fromTags, withTags, withOutTags)
        dispatch(setWordsList(result));
      }    
      if(firstUpdate.current){
        firstUpdate.current=false;
        return;
      } 
      getWords();      
    },[fromTags, withTags, withOutTags]);



  function selectedFromTag(event, value){
    Cookies.set('fromTags',JSON.stringify(value),{ expires: 7 });
    setFromTags(value);
  }

  function selectedWithTag(event, value){
    Cookies.set('withTags',JSON.stringify(value),{ expires: 7 });
    setWithTags(value);
  }

  function selectedWithOutTag(event, value){
    Cookies.set('withOutTags',JSON.stringify(value),{ expires: 7 });
    setWithOutTags(value);
  }


  return (<div> 
      <img alt ='' className="logo-image" src={require('./italoDisco.png')} />
      <Grid container spacing={1.5} fullWidth marginBottom={1.5} marginTop={1.5} >
      <Grid item xs={4}>
        <TagSelectBox label = "With tags" onChange={selectedWithTag} tagsList={tagsList} value={withTags}/>
      </Grid>
      <Grid item xs={4}>
        <TagSelectBox label = "From tags" onChange={selectedFromTag} tagsList={tagsList} value={fromTags}/> 
      </Grid>
      <Grid item xs={4}>
        <TagSelectBox label = "With-out tags" onChange={selectedWithOutTag} tagsList={tagsList} value={withOutTags}/>
      </Grid>
    </Grid>
      <div>
        <WordsTable />
      </div>
  </div>);
}

export {StartPage};