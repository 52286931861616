import {Box, Button, Grid} from '@mui/material';
import { saveAs } from 'file-saver';
import Api from '../../api/wordsApi';

function ExportWordsPanel(props){
    return (<Box width="100%" height="100%">
     <Grid container spacing={1.5} fullWidth>
        <Grid item xs={4}>
            <Button fullWidth={true} color="error" variant="outlined" size="large" onClick={props.handleClose}>Cancel</Button>
        </Grid>
        <Grid item xs={8}>
            <Button fullWidth={true} color="success" variant="contained" size="large" onClick={ExportWords}>Export words</Button>
        </Grid>
    </Grid>
  </Box>)
}

async function ExportWords(){
  const words = await Api.getWords("","");
  const exportJson = {
    words:words
  };
  var blob = new Blob([JSON.stringify(exportJson,null,2)], {type: "application/json;charset=utf-8"});
  saveAs(blob, "words_export_" + (new Date()).toISOString() + ".json");
}

export default ExportWordsPanel;