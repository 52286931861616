import {Box, Grid, InputLabel, TextField, Switch} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import { enable, disable } from '../../slice/options/reverseCardSlice'
import { set } from '../../slice/options/wordsPerPageSlice'
import CookiesHelper from '../../helpers/CookiesHelper';


function BasicOptionsPanel(){
  const reverseCard = useSelector((state) => state.reverseCard.value);
  const wordsPerPage = useSelector((state) => state.wordsPerPage.value);
  const dispatch = useDispatch();

  function handleReverseCardChange(event, value){
    CookiesHelper.setReverseCard(value);
    if(value){
      dispatch(enable());
    } else {
      dispatch(disable());
    }
  }

  function handleChangeWordsPerPage(event, value){
    let wordsPerPage = parseInt(event.target.value);
    dispatch(set(wordsPerPage));
    CookiesHelper.setWordsPerPage(wordsPerPage);
  }

    return (<Box width="100%" height="100%">
    <Grid container spacing={3}>
      <Grid item xs={6}> <InputLabel>Reverse word and translation</InputLabel></Grid>
      <Grid item xs={6}> <Switch 
      checked={reverseCard}
      onChange={handleReverseCardChange}
      /></Grid>
      <Grid item xs={6}><InputLabel>Words per page</InputLabel></Grid>
      <Grid item xs={6}>
        <TextField
          id="outlined-number"
          type="number"
          size="small"
          onBlur={handleChangeWordsPerPage}
          defaultValue={wordsPerPage}
          sx = {{
            width:"60px"
          }}
          InputLabelProps={{
            shrink: true,
          }}/>
      </Grid>
    </Grid>
  </Box>)
}

export default BasicOptionsPanel;