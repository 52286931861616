import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import {useState, useEffect} from "react";

import TagAutocompleteBox from './TagAutocompleteBox';


function WordEditBox(props) {
    const [wordFormInput, setWordFormInput] = useState({});
    const [wordTextError, setWordTextError] = useState(false);
    const [translationTextError, setTranslationTextError] = useState(false);

    useEffect(() => {
        setWordFormInput(props.inputs);
    },[props.inputs]);

    useEffect(() => {
        setWordTextError(props.wordTextError);
        setTranslationTextError(props.translationTextError);
    }, [props.wordTextError, props.translationTextError]); 

    useEffect(() => {
        props.handleInputChange({}, wordFormInput);
    },[wordFormInput]) // eslint-disable-line react-hooks/exhaustive-deps


    const handleWordInputChange = (event, v) => {
        const name = event.target.name;
        const value = (v || event.target.value);
        setWordFormInput(values => ({...values, [name]: value}))
    }

    //TODO: not working onChange for some reason, fix this...
    //function EditBoxTextField(){
    //    return (<TextField name='word' defaultValue={props.inputs?.word || ''} error={wordTextError} autoComplete='off' color="success" required fullWidth label="Word" onChange={handleWordInputChange} variant="outlined" />)   
    //}

    return(<Grid container spacing={1.5} fullWidth marginBottom={1.5}>
        <Grid item xs={12}>
            <TextField name='word' defaultValue={props.inputs?.word || ''} error={wordTextError} autoComplete='off' color="success" required fullWidth label="Word" onChange={handleWordInputChange} variant="outlined" />
        </Grid>
        <Grid item xs={2}>
            <TextField name='translationDetails' defaultValue={props.inputs?.translationDetails || ''} autoComplete='off' fullWidth color="success" width="20px" onChange={handleWordInputChange} label="Article" variant="outlined" />
        </Grid>
        <Grid item xs={10}>
            <TextField name='translation' defaultValue={props.inputs?.translation || ''} error={translationTextError} autoComplete='off' fullWidth color="success" required width="20px" onChange={handleWordInputChange} label="Translation" variant="outlined" />
        </Grid>
        <Grid item xs={12}>
            <TextField name='translationDescription' multiline defaultValue={props.inputs?.translationDescription || ''} autoComplete='off' fullWidth color="success" width="20px" onChange={handleWordInputChange} label="Additional info" variant="outlined" />
        </Grid>
        <Grid item xs={12}>
            <TagAutocompleteBox name='tags' defaultValue={props.inputs?.tags || ''} onChange={handleWordInputChange}/>
        </Grid>
    </Grid>);
}

export default WordEditBox;