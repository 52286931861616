import { createSlice } from '@reduxjs/toolkit'

export const wordsListSlice = createSlice({
  name: 'wordsList',
  initialState: {
    value: []
  },
  reducers: {
    update: (state, action) => {
      let oldId = action.payload.oldId;
      let newWord = action.payload.newWord;
      state.value = [...state.value].map(e => {
        if(e.id===oldId){
          return {...e, ...newWord};
        } 
        return e;
      })
    },
    remove: (state, action) => {
      state.value = [...state.value].filter(e => e.id!==action.payload);
    },
    set: (state, action) => {
      state.value = action.payload;
    }
  },
})

export const { set, update, remove } = wordsListSlice.actions

export default wordsListSlice.reducer