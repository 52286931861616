import { configureStore } from '@reduxjs/toolkit'
import reversCardSlice from './slice/options/reverseCardSlice'
import wordsPerPageSlice from './slice/options/wordsPerPageSlice'
import wordsListSlice from './slice/words/wordsListSlice'

export default configureStore({
  reducer: {
    reverseCard: reversCardSlice,
    wordsPerPage: wordsPerPageSlice,
    wordsList: wordsListSlice
  },
})