import {useState} from "react";
import {Button} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

import EditWordDialog from '../EditWordDialog'

function WordsTableRow(props){
    let [isEditWindowOpen, setIsEditWindowOpen] = useState(false);

    function OpenEditMenu() {
        setIsEditWindowOpen(true);
    }

    function HideEditMenu() {
        setIsEditWindowOpen(false);
    }
    
    function VisibleCell(props) {
        return (<div><div>{props.word}</div><div className="tag-font">{[...props.tags].sort().join(', ')}</div></div>);
    }

    function HiddenCell(props) {
        const [wasClicked, setVisible] = useState(false);
        return (<div>
            {!wasClicked ? <Button fullWidth={true} color="success" variant="contained" size="large" onClick={() => setVisible(true)}>Show</Button> : <div>{props.translation}</div>
            }
        </div>);
    }
    let visibleSide = (props.reverse?props.backSide:props.fronSide);
    let hiddenSide = (props.reverse?props.fronSide:props.backSide);
    return (
        <tr>
            <td><VisibleCell word={visibleSide} tags={props.tags} /></td>
            <td><HiddenCell translation={hiddenSide}/></td>
            <td>{isEditWindowOpen && <EditWordDialog open={isEditWindowOpen} handleClose={HideEditMenu} word={props.word} />}<Button color="success" size= "large" variant="contained" onClick={OpenEditMenu}><EditIcon/></Button></td>
        </tr> 
    )
}

export default WordsTableRow;