function OptionsTabPanel(props) {
    const { value, index, children } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {value === index && (<div>{children}</div>)}
      </div>
    );
  }

export default OptionsTabPanel;