import './WordsTable.css';
import {useEffect, useState} from "react";
import {Button} from "@mui/material";
import WordsTablePage from "./WordsTablePage";
import { useSelector } from 'react-redux'


function WordsTable() {
    const [wordsOnCurrentPage, setWordsOnCurrentPage] = useState([]);
    const reverseCard = useSelector((state) => state.reverseCard.value);
    const wordsPerPage = useSelector((state) => state.wordsPerPage.value);
    const wordsList = useSelector((state) => state.wordsList.value);

    let [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        _setWordsOnPage();
    }, [currentPage, wordsPerPage]); 

    useEffect(() => {
        if(!_doesPageStillExist()){
            setCurrentPage(Math.max((_numberOfPages()-1),0));
        }
        _setWordsOnPage(wordsList);
    }, [wordsList]); 


     function _setWordsOnPage(currentwordsList = wordsList){ 
         let from = (currentPage*wordsPerPage);
         let to = Math.min(((currentPage*wordsPerPage) + wordsPerPage),currentwordsList.length);
         let currentPageWords = [...currentwordsList].slice(from, to);
         setWordsOnCurrentPage(currentPageWords);
     }

     function _doesPageStillExist(){
        return ((currentPage + 1) <= (_numberOfPages()))
     }

     function _isFirstPage(){
        return currentPage===0;
    }

    function _isLastPage(){
        return (_numberOfPages()-1)===currentPage;
    }

    function _arePages(){
        return _numberOfPages()!==0;
    }

    function _numberOfPages(){
        return Math.ceil(wordsList.length/wordsPerPage);
    }

    function _switchToNextPage(){
        setCurrentPage(++currentPage);
    }

    function _switchToPreviousPage(){
        if(currentPage!==0){
            setCurrentPage(--currentPage);
        }
    }

    //should be separate component?
    function NavigationButtons(){
        function PreviousPageButton() {
            return (!_isFirstPage() && _arePages()) ? <Button fullWidth color="success" variant="outlined" size="large" onClick={_switchToPreviousPage}>Previous Page</Button>: <div/>;
        }
    
        function NextPageButton() {
            return (!_isLastPage() && _arePages()) ? <Button fullWidth color="success" variant="outlined" size="large" onClick={_switchToNextPage}>Next Page</Button>: <div/>;
        }

        return <div className="grid-container">
            <div className="grid-item">{PreviousPageButton()}</div>
            <div className="grid-item" ><font className="page-number-font">{currentPage+1}/{_numberOfPages()}</font></div>
            <div className="grid-item">{NextPageButton()}</div>
        </div>
    }


    return (<div>
                <WordsTablePage wordsOnPage={wordsOnCurrentPage} reverse={reverseCard} />
                <NavigationButtons/>
            </div>)

}

export {WordsTable};