import {useEffect, useState} from "react";
import {Autocomplete} from "@mui/material";
import TextField from '@mui/material/TextField';

import Api from '../api/wordsApi';

function TagAutocompleteBox(props) {
    const [tagsList, setTagsList] = useState([]);

    useEffect(() => {
        getTags();
    },[]);


    async function getTags() { 
        let result = await Api.getTags();
        setTagsList(result);
    }

    function handleChange(event, value){
        event.target.name = props.name;
        props.onChange(event, value);
    }
 
    return (
      <Autocomplete
        freeSolo
        id="test-auto"
        disablePortal
        options={tagsList}
        multiple="true"
        name={props.name}
        fullWidth
        defaultValue={props.defaultValue || []}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} label= {"Tags"} />}
        componentsProps={{ //TODO: this popper doesn't look good
            popper: {       //TODO: add search phrase highlight in the future
                placement:"top" //TODO: create one component (filled with Tags alread maybe)
            }
          }}
      />
    );
  }

  export default TagAutocompleteBox;