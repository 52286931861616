import {Autocomplete} from "@mui/material";
import {TextField} from "@mui/material";

function TagSelectBox(props) {
    return (
      <Autocomplete
        {...props}
        disablePortal
        options={props.tagsList}
        multiple="true"
        fullWidth
        onChange={props.onChange}
        renderInput={(params) => <TextField {...params} label= {props.label} />}
      />
    );
  }

export default TagSelectBox;