import Cookies from 'js-cookie';

const DEFAULT_WORDS_PER_PAGE =  7;
const DEFAULT_COOKIE_EXPIRATION_DATE =  { expires: 7 };

const WORDS_PER_PAGE_COOKIE_NAME = 'wordsPerPage';
const REVERSE_CARD_COOKIE_NAME = 'reverseCard';


function setReverseCard(value){
    if(__isTrue(value)) {
        Cookies.set(REVERSE_CARD_COOKIE_NAME,true,DEFAULT_COOKIE_EXPIRATION_DATE);
    } else {
        Cookies.set(REVERSE_CARD_COOKIE_NAME,false,DEFAULT_COOKIE_EXPIRATION_DATE);
    }
}

function getReverseCard() {
    let reverseCardCookie = Cookies.get(REVERSE_CARD_COOKIE_NAME);
    return __isTrue(reverseCardCookie);
}


function setWordsPerPage(value){
    if(__isWordsPerPageCookieValid(value)){
        Cookies.set(
            WORDS_PER_PAGE_COOKIE_NAME
            ,parseInt(value)
            ,DEFAULT_COOKIE_EXPIRATION_DATE
        );
    } 
}

function getWordsPerPage() {
    let wordsPerPageCookie = parseInt(Cookies.get(WORDS_PER_PAGE_COOKIE_NAME));
    if(__isWordsPerPageCookieValid(wordsPerPageCookie)){
        return +wordsPerPageCookie;
    }
    return DEFAULT_WORDS_PER_PAGE;
}


function __isTrue(value){
    return value && (value === true || value === 'true' || value === 1);
}

function __isWordsPerPageCookieValid(value) {
    return (value && __isNumber(value) && __isWordsPerPageInRange(value));
}

function __isNumber(value) {
    return typeof value === 'number';
}

function __isWordsPerPageInRange(value) {
    return 1 <= value && value <=15;  
}


const CookiesHelper = {
    setWordsPerPage:setWordsPerPage,
    getWordsPerPage:getWordsPerPage,
    getReverseCard:getReverseCard,
    setReverseCard:setReverseCard
}

export default CookiesHelper;