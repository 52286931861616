import {Box, Button } from '@mui/material';
import {useState} from "react";
import Grid from '@mui/material/Grid';
import {InputLabel} from '@mui/material';

import {WirdEditAlertBar, WordEditStatus} from '../WordEditAlertBar';
import WordEditBox from '../WordEditBox';
import Api from '../../api/wordsApi';


function AddWordPanel(props){
    return (<Box width="100%" height="100%">
    <ComposeTextFields handleClose={props.handleClose}/>
  </Box>)
}

function ComposeTextFields(props){
    const [wordFormInput, setWordFormInput] = useState({});

    const [wordTextError, setWordTextError] = useState(false);
    const [translationTextError, setTranslationTextError] = useState(false);

    const [wordAddStatus, setWordAddStatus] = useState(WordEditStatus.None);

    
    const handleWordInputChange = (event, value) => {
        setWordFormInput(value);
    }

    async function addWord(wordToSave) {
        const response = await Api.addWord(wordToSave);
        return await response;
    }
    
    async function AddWordClick(){
        setWordAddStatus(WordEditStatus.None);
        if(_isWordFieldValid() && IsTranslationieldValid()){
            let result = await addWord(wordFormInput);
            if(result && result.wordsAddedCount!==0) {
                setWordAddStatus(WordEditStatus.Success);
                _markRequiredFields();
                props.handleClose();
            } else {
                setWordAddStatus(WordEditStatus.AlreadyExist);
                _wordAlreadyExists()
            }
        } else {
            setWordAddStatus(WordEditStatus.BadInput);
            _markRequiredFields()
        }
    }

    function _markRequiredFields() { 
        setWordTextError(!_isWordFieldValid());
        setTranslationTextError(!IsTranslationieldValid());
    }

    function _wordAlreadyExists() { 
        setWordTextError(true);
        setTranslationTextError(true);
    }
    
    function _isWordFieldValid(){
        return (wordFormInput.word && wordFormInput.word.trim() !== '')
    }

    function IsTranslationieldValid(){
        return (wordFormInput.translation && wordFormInput.translation.trim() !== "")
    }

    return(<div>
        <InputLabel size="large">Add new word:</InputLabel>
        <WordEditBox 
            handleInputChange = {handleWordInputChange}
            wordTextError = {wordTextError}
            translationTextError = {translationTextError}
        />
        <Grid container spacing={1.5} fullWidth>
        <Grid item xs={4}>
            <Button fullWidth={true} color="error" variant="outlined" size="large" onClick={props.handleClose}>Cancel</Button>
        </Grid>
        <Grid item xs={8}>
            <Button fullWidth={true} color="success" variant="contained" size="large" onClick={AddWordClick}>Add word</Button>
        </Grid>
        <Grid item xs={12}>
            <WirdEditAlertBar wordAddStatus={wordAddStatus}/>
        </Grid>
    </Grid></div>)
}

export default AddWordPanel;