import {Dialog } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {useState} from "react";

import OptionsTabPanel from './OptionsTabPanel'
import AddWordPanel from './AddWordPanel'
import ExportWordsPanel from './ExportWordsPanel'
import BasicOptionsPanel from './BasicOptionsPanel'


function OptionsMainWindow(props){
    return(<Dialog
        open={props.isOptionMenuOpen}
        onClose={props.handleClose}
    >   
       <TabMenu handleClose={props.handleClose} />
    </Dialog >);
}


function TabMenu (props) {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <Paper className='standard-margin' elevation={3}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'success' }}>
            <Tabs value={value} onChange={handleChange} >
              <Tab label="Settings" />
              <Tab label="Add word" />
              <Tab label="Export" />
              <Tab label="Known issues" />
            </Tabs>
          </Box>
          <Box sx={{ p: 3 }}>       
            <OptionsTabPanel index={0} value={value}><BasicOptionsPanel handleClose={props.handleClose} /></OptionsTabPanel>
            <OptionsTabPanel index={1} value={value}><AddWordPanel handleClose={props.handleClose} /></OptionsTabPanel>
            <OptionsTabPanel index={2} value={value}><ExportWordsPanel handleClose={props.handleClose} /></OptionsTabPanel>
            <OptionsTabPanel index={3} value={value}><Box>
              <p>* Word list not refreshes after word edit/delete</p>
              </Box></OptionsTabPanel>
          </Box>
        </Box>
        </Paper>
      );
}





export default OptionsMainWindow;